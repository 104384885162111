<template>
  <div class="discount">
    <div class="public-margin-bottom">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addDiscount"
        >新增比例</el-button
      >

      <el-button
        v-show="authType == 'H'"
        type="success"
        size="mini"
        @click="onShowSync"
        >同步折扣</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="calc(100vh - 200px)"
      stripe
    >
      <el-table-column width="48">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            v-model="isActiveCheck"
            :checked="allCheck"
            :indeterminate="indeterminate"
            @change="onSelectChange('all', tableData)"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            :value="scope.row.isCheck"
            :checked="scope.row.isCheck"
            :indeterminate="false"
            @change="onSelectChange('', scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="DiscNo" label="折扣编号"> </el-table-column>
      <el-table-column prop="DiscName" label="折扣名称"> </el-table-column>
      <el-table-column prop="DiscRatio" label="折扣比例"> </el-table-column>
      <el-table-column label="总部">
        <template slot-scope="scope">
          <div>{{ scope.row.HeadMark | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="onEditDiscount(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="onDeleteDiscount(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>

    <!-- 同步至门店对话框 -->
    <DfSyncStoreDialog
      ref="DfSyncStoreDialog"
      :syncFuction="synParadisc"
    ></DfSyncStoreDialog>
  </div>
</template>

<script>
import setting from "@/api/setting.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  name: "StoreWebCategory",

  components: { EditDialog },

  directives: {},

  data() {
    return {
      authType: "",
      tableData: [],
      formData: {},
      etitle: "",
      showDialog: false,

      requestPowerParam: {
        // 控制权限的对话框
        show: false,
        key: "",
        code: "",
        power: "",
        callback: () => {},
      },
      selectItems: [],
      isActiveCheck: false,
      allCheck: false,
      indeterminate: false,
    };
  },

  mounted() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    async initPageData(itemGuids = []) {
      this.tableData = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;

      try {
        let { data } = await setting.getParadisc();
        // if (itemGuids.length > 0) {
          //   this.isActiveCheck = true;
          //   this.onSelectChange("all", data);
          //   return;
          // } else if (this.selectItems.length > 0) {
          //   this.tableData = data;
          //   this.onRowSelection();
          //   return;
          // }
        this.tableData = data;
      } catch (e) {
        console.error(e);
      }
    },

    addDiscount() {
      this.etitle = "添加比例";
      this.formData = {
        DiscName: "",
        DiscRatio: "",
      };
      this.showDialog = true;
    },

    onEditDiscount(event) {
      this.etitle = "修改比例";
      this.formData = event;
      this.showDialog = true;
    },

    async onDeleteDiscount(event) {
      this.$confirm(`是否删除该‘${event.DiscName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await setting.deleteParadisc({
              discGuid: event.DiscGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    // 同步
    async synParadisc(event, callback) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems));
	  
      try {
        let res = await setting.synParadisc(event.authKey, event.authCode, {
          SourceStoresGuid: window.sessionStorage.getItem("selectStoreGuid"),
          TargetStoresGuid: event.targetStoresGuid,
          SelectDiscGuids: selectItems.map((e) => e.DiscGuid),
        });
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },

	// 多选框改变事件
    async onSelectChange(event = "", row) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems)),
        tableData = JSON.parse(JSON.stringify(row));
      this.loading = true;
      if (event == "all") {
        selectItems = [];
        await tableData.forEach((e) => {
          if (this.isActiveCheck) {
            e.isCheck = true;
            selectItems.push(e);
          } else {
            selectItems = [];
            e.isCheck = false;
          }
        });
        this.tableData = await tableData;
      } else {
        let index = await selectItems.findIndex(
          (e) => e.DiscGuid == row.DiscGuid
        );
        index > -1
          ? selectItems.splice(index, 1)
          : (selectItems = [...selectItems, row]);
        row.isCheck = !row.isCheck;
      }

      this.selectItems = await selectItems;
      await this.onAllSelection();
      await setTimeout(() => {
        this.loading = false;
      }, 200);
      // this.selectItems = [...this.selectItems, row]
      // console.log(this.isActiveCheck, this.allCheck, this.indeterminate);
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(async () => {
        let count = 0;
        // console.log(this.tableData, this.selectItems);
        await this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].DiscGuid === item.DiscGuid) {
              count++;
              // console.log(this.tableData[index], item);
              // this.$refs.multipleTable.toggleRowSelection(
              //   this.tableData[index],
              //   true
              // );
              this.tableData[index].isCheck = true;
              break;
            }
          }
        });

        if (count > 0) {
          await this.onAllSelection();
        } else {
          this.isActiveCheck = false;
          this.allCheck = false;
          this.indeterminate = false;
        }
      });
    },

    // 全选回显
    async onAllSelection() {
      this.isActiveCheck = (await this.selectItems.length) > 0 ? true : false;
      this.allCheck =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length == this.tableData.length
          ? true
          : false;
      this.indeterminate =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length != this.tableData.length
          ? true
          : false;
    },

    // 打开同步对话框
    onShowSync() {
      this.util.checkPower("1007", this.requestPowerParam, async () => {
        let arr = await this.selectItems.map((e) => e.DiscGuid);
        if (!arr.length) {
          return this.$message.error("请先选择折扣");
        }
        this.$refs.DfSyncStoreDialog.onShowDialog(arr);
      });
    },

    onCloseDialog() {
      this.initPageData();
    },

    callFatherFunction() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.discount {
  height: 100%;
  padding-top: 20px;
}
</style>
